import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "signatures",
  "category": "Getting Started",
  "sort": 4,
  "title": "UI Customization",
  "subtitle": "The signatory frontend UI can be easily be customized with your logo and theme colors."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The goal of Criipto Signatures is to be fully customizable to fit exactly into your workflow.
In the short term you can set a logo and theme the UI via CSS variables.`}</p>
    <p>{`All UI settings are signature order specific which makes it easy for you to customize based on the context, and you can easily test new UI settings without affecting any new signature orders.`}</p>
    <p>{`When creating a signature order via `}<inlineCode parentName="p">{`createSignatureOrder`}</inlineCode>{` you can customize the UI by defining the subproperties of the `}<inlineCode parentName="p">{`ui`}</inlineCode>{` input variable.`}</p>
    <p><a parentName="p" {...{
        "href": "/signatures/graphql/examples/#signature-order-ui-settings"
      }}>{`Full UI settings GraphQL Example`}</a></p>
    <h2>{`Logo`}</h2>
    <p>{`A logo can be added to the top of the signatory screen.`}</p>
    <p>{`Set `}<inlineCode parentName="p">{`ui.logo.src`}</inlineCode>{` to an absolute HTTPs image url and we will add the image to the top of the signatory frontend.`}</p>
    <p>{`You can also define `}<inlineCode parentName="p">{`ui.logo.href`}</inlineCode>{` which turns the logo into a link.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "input": {
    "ui": {
      "logo": {
        "src": "https://www.criipto.com/hubfs/logo.svg", // required
        "href": "https://www.criipto.com" // can be left null
      }
    }
  }
}
`}</code></pre>
    <h2>{`Stylesheet`}</h2>
    <p>{`A custom stylesheet can be added to the frontend to provide unique theming and styling options.`}</p>
    <p>{`Set `}<inlineCode parentName="p">{`ui.stylesheet`}</inlineCode>{` to an absolute HTTPs css url and it will be loaded when the signatory opens their link.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "input": {
    "ui": {
      "stylesheet": "https://signatures-storybook.criipto.com/custom.css"
    }
  }
}
`}</code></pre>
    <p>{`The fastest way to theme the UI would be to override our CSS variables to show your primary colors.`}</p>
    <pre><code parentName="pre" {...{}}>{`.criipto-signatures {
  --primary-rgb: 128, 0, 128; // Change the primary color of the UI to purple.
}
`}</code></pre>
    <h3>{`Storybook`}</h3>
    <p>{`You can test your stylesheet without creating a signature order by using our Storybook (BETA) which is a static rendering of our UI with dummy data.`}</p>
    <p><a parentName="p" {...{
        "href": "https://signatures-storybook.criipto.com"
      }}>{`Open the Signatures Storybook`}</a>{` and then paste a stylesheet URL into the "Stylesheet URL" input field, you can use `}<inlineCode parentName="p">{`https://signatures-storybook.criipto.com/custom.css`}</inlineCode>{` to test.`}</p>
    <h3>{`CSS Variables`}</h3>
    <p>{`Below you will find a list of all CSS variables that can currently be overriden.`}</p>
    <pre><code parentName="pre" {...{}}>{`.criipto-signatures {
  --primary-rgb: 32, 76, 130;
  --danger-rgb: 206, 112, 101;
  --success-rgb: 128, 231, 144;

  --font-family: Raleway;

  --button-primary-background-rgb: var(--primary-rgb);
  --button-primary-color-rgb: 255, 255, 255;

  --button-default-background-rgb: 255, 255, 255;
  --button-default-color-rgb: var(--primary-rgb);

  --button-danger-background-rgb: var(--danger-rgb);
  --button-danger-color-rgb: 255, 255, 255;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      